import * as React from "react";
import {useRef, useEffect} from 'react'

import Layout from '../components/layout'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

function getPostition(el, height){

    const rect = el.getBoundingClientRect();

    var top = rect.top + window.scrollY;
    
    return top;
    
}

function IndexPage(){
    const spanCats = useRef(null);
    const movable1 = useRef(null);
    const totarget = useRef(null);

    const lead1 = useRef(null);
    const lead2 = useRef(null);
    const lead3 = useRef(null);
    const lead4 = useRef(null);

    //entry animations

    useEffect(() => {
        const l1 =  lead1.current;
        const l2 =  lead2.current;
        const l3 =  lead3.current;
        const l4 =  lead4.current;


        const tl = gsap.timeline()
        let tweet = tl.to(l1,{x: 200, duration: 2, ease: 'none'})
        
        tweet.play();
  
      }, [])

    //scrolling the word cat

    useEffect(() => {
        const cS =  spanCats.current;
        const m1 =  movable1.current;
        const m2 =  totarget.current;

        const positionOfTarget = getPostition(m2, false);
        const positionOfStart = getPostition(cS, false);
        const tweetvalue = positionOfStart-positionOfTarget;
        const tl = gsap.timeline()
        .to(m2,{y: '-=' + positionOfStart, duration: 0.1, ease: 'none'})
        .to(m2,{y: '+=' + tweetvalue, duration: 8, ease: 'none'})
        
        
        ScrollTrigger.create({
          animation: tl,
          trigger: m1,
          start: "top top",
          end: "+=50%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim"
        });
  
      }, [])
      
    useEffect(() => {
        const cS =  spanCats.current;
        const m1 =  movable1.current;
        const m2 =  totarget.current;

        const positionOfTarget = getPostition(m2, false);
        const positionOfStart = getPostition(cS, false);
        const tweetvalue = positionOfTarget-positionOfStart;
        const tl = gsap.timeline()
        .to(cS,{y: '+=' + tweetvalue, duration: 8, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl,
          trigger: m1,
          start: "top top",
          end: "+=50%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim"
        });
  
      }, [])


    return (
        <Layout pageTitle="Home Page" > 
            <div className="min-w-full max-w-full" >
                <section ref={movable1} className="relative flex flex-row justify-center items-center w-full h-screen  bg-gradient-to-r from-purple3 to-pink4">
                    <div className="leading-tight text-white">
                        <div className="text-[10vw]" ref={lead1}>There are over</div>
                        <div className="text-[10vw]" ref={lead2}>100 Million</div>
                        <div className="flex flex-row">
                            <div className="text-[10vw]" ref={lead3}>pet</div>
                            <div className="text-[10vw] px-10 text-black" ref={spanCats}>cats</div>
                            
                            <div className="text-[10vw]" ref={lead4}>in the US</div>
                        </div>
                    </div>
                </section>
                <section className="self-center w-full relative bg-white">
                    <div className="text-white self-center leading-tight text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4">
                        <div className="flex flex-row">
                        <div className="text-[10vw] px-10 text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4" ref={totarget}>cats</div>
                        </div>
                        <div className="text-[10vw]">with loving parents</div>
                        <div className="text-[10vw]">that celebrate</div>
                        <div className="text-[10vw]">their birthdays</div>
                        
                    </div>
                </section>
                <section className="bg-white self-center w-full">
                    <div className="self-center leading-tight text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4">
                        <div className="flex flex-row">
                            <div className="text-[10vw] pl-[10vw]">cats</div>
                            <div className="text-[10vw]" >with</div>
                            <div className="text-[10vw]">loving</div>
                        </div>
                        <div className="text-[10vw]">parents that celebrate</div>
                        <div className="text-[10vw]">their birthdays</div>
                        
                    </div>
                </section>
            </div>
     </Layout>
    )
}  
export default IndexPage